import Dropdown from "react-bootstrap/Dropdown";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import "./InfraStructure.css";
import downArrow from "../../../images/downVector.svg";
import searchImg from "../../../images/searchIcons.svg";
import vector from "../../../images/Vector.svg";
import file from "../../../images/file.svg";
import drag from "../../../images/drag.svg";
import azIcon from "../../../images/AZIcon.svg";
import arrow_right from "../../../images/arrow-right.svg"
import checkSvg from "../../../images/check.svg";
import { useState, useEffect, useContext, useRef } from "react";
import crossImg from "../../../images/clear.svg"
import Vpcs from "./Vpcs";
import AvailabilityZone from "./AvailabilityZone";
import Regions from "./Regions";
import UniversalResource from "./UniversalResource";
import CreateContext from "../../../store/create-context";
import ResourceIconPopUp from "./component/resourceIcons/ResourceIconPopUp";
import { resourceIcon } from "./Regions";
import { fetchSubnetData, getAllCategories, getSubnetData, sliceSubArr } from "./Subnet";


const InfraStructure = ({infraViewResponse}) => {
  const [awsAccounts, setAwsAccounts] = useState([]);
  const [regions, setRegions] = useState([]);
  const [availZones, setAvailZones] = useState([]);
  const [vpcs, setVpcs] = useState([]);
  const [openedDropDowns, setIsOpenedDropDowns] = useState({
    Account: false,
    Regions: false,
  });
  const [globalData, setGlobalData] = useState({});
  const [selectedDropDownValues, setSelectedDropDownValues] = useState({
    "aws:account": "",
    "aws:region": "",
  });
  const [hideGlobal, setHideGlobal] = useState(true);
  const [searchBar, setSearchBar] = useState("");
  const {selectedInfraviewDetail, setSelectedInfraviewDetail} = useContext(CreateContext);
  const [projectId, setProjectId] = useState(sessionStorage.getItem("jml_workspace_id"));
  const [selectedResource, setSelectedResource] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState(null);
  const [target, setTarget] = useState();
  const [showPopUp, setShowPopUp] = useState();
  const [openCards, setOpenCards] = useState({});
  const [hideAz, setHideAz] = useState(true);
  const [allOpen, setAllOpen] = useState(false);
  const [maxCategories, setMaxCategories] = useState(0);
  const azRef = useRef([]);
  const globalRef = useRef(null);

  useEffect(() => {
    const max = Math.max(
      ...availZones.map((az) => Object.keys(az.children.categories).length)
    );
    setMaxCategories(max);
  }, [availZones]);

  useEffect(() => {
    let userAlreadySelectedItems =  selectedInfraviewDetail.find((item) => item.projectId === projectId);
    const awsAccountArr = infraViewResponse.nodes.filter(
      (el) => el.type === "aws:account"
    );
    setAwsAccounts(awsAccountArr);
    handleAccountChange(JSON.stringify(awsAccountArr[0]));
    if(userAlreadySelectedItems){
      const copyElement = {...userAlreadySelectedItems};
      setRegions(copyElement.regions);
      setGlobalData(copyElement.globalData);
      setAvailZones(copyElement.availZones);
      setVpcs(copyElement.vpcs);
      setSelectedInfraviewDetail((prev) => {
          return prev;
      });
      if(copyElement){
          delete copyElement.projectId;
          setSelectedDropDownValues((prev) => ({...prev, ...copyElement}));
      }
    }else if (awsAccountArr.length > 0) {
      setSelectedDropDownValues((prev) => ({
        ...prev,
        [awsAccountArr[0].type]: awsAccountArr[0],
      }));
      const findawsRegionArr = infraViewResponse.nodes.filter(
        (el) =>
          el.type === "aws:region" &&
          ((Array.isArray(el.parent) &&
            el.parent.includes(awsAccountArr[0].id)) ||
            el.parent === awsAccountArr[0].id)
      );
      if (findawsRegionArr.length > 0) {
        const regionArr = addChildrens(findawsRegionArr);
        regionArr.sort(
          (a, b) => b.children.child.length - a.children.child.length
        );
        setRegions(regionArr);
        setSelectedDropDownValues((prev) => ({
          ...prev,
          [regionArr[0].type]: regionArr[0],
        }));
        handleRegionChange(JSON.stringify(regionArr[0]));
      }
    }
  }, []);

  useEffect(() => {
    let newElement = true;
    setSelectedInfraviewDetail((prev) => {
      const updatedDetails = prev.map((el) => {
        if (el.projectId === projectId) {
          newElement = false;
          return {
            ...el,
            ...selectedDropDownValues,
            regions,
            globalData,
            availZones,
            vpcs,
          };
        }
        return el;
      });
      if (newElement) {
        return [
          ...updatedDetails,
          {
            ...selectedDropDownValues,
            projectId,
            regions,
            globalData,
            availZones,
            vpcs,
          },
        ];
      }
      return updatedDetails;
    });
  }, [
    selectedDropDownValues,
    awsAccounts,
    projectId,
    regions,
    globalData,
    availZones,
    vpcs,
  ]);

  const handleAccountChange = (e) => {
    clickOnDropDown("Account");
    setIsOpenedDropDowns((prev) => ({ ...prev, Account: !prev["Account"] }));
    let jsonElement = JSON.parse(e);
    setSelectedDropDownValues((prev) => ({
      ...prev,
      [jsonElement.type]: jsonElement,
    }));
    const findglobal = infraViewResponse.nodes.find(
      (element) =>
        ((Array.isArray(element.parent) &&
          element.parent.includes(jsonElement.id)) ||
          element.parent === jsonElement.id) &&
        element.type === "aws:global"
    );
    addGlobalChildrens(findglobal);
    setGlobalData(findglobal);

    const findChilds = infraViewResponse.nodes.filter(
      (element) =>
        ((Array.isArray(element.parent) &&
          element.parent.includes(jsonElement.id)) ||
          element.parent === jsonElement.id) &&
        element.type === "aws:region"
    );

    if (findChilds.length > 0) {
      const regionArr = addChildrens(findChilds);
      regionArr.sort(
        (a, b) => b.children.child.length - a.children.child.length
      );
      setRegions(regionArr);
      handleRegionChange(JSON.stringify(regionArr[0]));
    } else {
      resetResources();
    }
  };

  const handleRegionChange = (e) => {
    let jsonElement = JSON.parse(e);
    setSelectedDropDownValues((prev) => ({
      ...prev,
      [jsonElement.type]: jsonElement,
    }));
    const findChildsAz = infraViewResponse.nodes.filter(
      (element) =>
        ((Array.isArray(element.parent) &&
          element.parent.includes(jsonElement.id)) ||
          element.parent === jsonElement.id) &&
        element.type === "aws:az"
    );
    const findChildsVPC = infraViewResponse.nodes.filter((element) => {
      return (
        ((Array.isArray(element.parent) &&
          element.parent.includes(jsonElement.id)) ||
          element.parent === jsonElement.id) &&
        element.type === "ec2:vpc"
      );
    });
    const azArr = findChildsAz && addChildrens(findChildsAz);
    const vpcArr = findChildsVPC && addChildrens(findChildsVPC);
    setAvailZones(azArr);
    setVpcs(vpcArr);
  };

  const addChildrens = (parentArr) => {
    const childrens = parentArr.map((element) => {
      const child = infraViewResponse.nodes.filter(
        (el) =>
          ((Array.isArray(el.parent) && el.parent.includes(element.id)) ||
            el.parent === element.id) &&
          el.type !== "aws:region" &&
          el.type !== "aws:az" &&
          el.type !== "ec2:vpc" &&
          el.type !== "ec2:subnet"
      );
      const categories = getAllCategories(child)
      element.children = { child, categories};
      return element;
    });
    return childrens;
  };

   const addGlobalChildrens = (globalElement) => {
    const child = infraViewResponse.nodes.filter(
      (el) =>
        ((Array.isArray(el.parent) && el.parent.includes(globalElement?.id)) ||
          el.parent === globalElement?.id) &&
        (el.type === "iam:role" ||
          el.type === "iam:user" ||
          el.type === "iam:policy")
    );
    const categories = getAllCategories(child)
    if (child.length !== 0) {
      globalElement.children = { child, categories};
      return globalElement;
    }
  };

  const clickOnDropDown = (clickedOn) => {
    setIsOpenedDropDowns((prev) => {
      return { ...prev, [clickedOn]: !prev[clickedOn] };
    });
  };

  const resetResources = () => {
    setRegions([]);
    setAvailZones([]);
    setVpcs([]);
    setSelectedDropDownValues((prev) => ({ ...prev, "aws:region": "" }));
  };

  const toggleUniversalResource = () =>{
    setHideGlobal(!hideGlobal);
  }

  const handleResourceIconClick = (e, resource) => {
    e.stopPropagation();
    if(selectedResource && resource.id === selectedResource.id) {
        setShowPopUp(false);
        setSelectedResource("");
    }else{
        setSelectedResource(resource);
        setShowPopUp(true);
        setTarget(e.target);
        setAdditionalInfo(null);
    }
  }

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>{
      setOpenCards((prev) => {
        const newState = { ...prev };
        newState[eventKey] = !prev[eventKey];
        return newState;
      });
    });
    return (
      <button
        type="button"
        onClick={decoratedOnClick}
        className="card-toggle-btn"
      >
        {children}
      </button>
    );
  }

  const highlightText = (text, search) => {
    if (!search) return text;
    const parts = text.split(new RegExp(`(${search})`, "gi"));
    return parts.map((part, index) =>(
      part.toLowerCase() === search.toLowerCase() ? (
        <span key={index} className="highlighted-text">
          {part}
        </span>
      ) : (
        <span>{part}</span>
      ))
    );
  };
  const filteredVpcs = vpcs?.filter(vpc =>
    vpc.name.toLowerCase().includes(searchBar.toLowerCase())
  ) || [];

  const filteredAvailableZone = availZones?.filter(az =>
    az.name.toLowerCase().includes(searchBar.toLowerCase())
  ) || [];

  const filterSubnets = (vpcs || [])
  .map(vpc => {
    const subnets = fetchSubnetData(vpc.id, availZones, infraViewResponse) || [];
    return subnets
      .map(subnet => sliceSubArr(subnet) || [])
      .reduce((acc, arr) => acc.concat(arr), []);
    })
  .reduce((acc, arr) => acc.concat(arr), [])
  .filter(sub => sub.name.toLowerCase().includes(searchBar.toLowerCase()));

  const filteredResults = [
    ...filteredVpcs,
    ...filteredAvailableZone,
    ...filterSubnets
  ];

  const toggleAllCards = () => {
    setAllOpen(prev => !prev);
  }

  const handleClickAz = (index) => {
    setHideAz((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  }

  const handleMouseDown = (index, e) => {
    e.preventDefault();
    const onMouseMove = (event) => handleMouseMove(index, event);
    const onMouseUp = () => handleMouseUp(onMouseMove, onMouseUp);
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  const handleMouseMove = (index, e) => {
    if (azRef.current[index]) {
      const newWidth = e.clientX - azRef.current[index].getBoundingClientRect().left;
      azRef.current[index].style.width = `${newWidth}px`;
    }
  };

  const handleMouseUp = (onMouseMove, onMouseUp) => {
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  };

  return (
    <div className="infrastructure-main">
      <div>
        <div className="first-div">
          <span className="infra-text">Infrastructure View</span>
          <div style={{ display: "flex", gap: "10px" }}>
            <div className="vector-img" onClick={toggleUniversalResource}>
              <img src={vector} alt="vector" />
            </div>
            <div className="vector-img" onClick={toggleAllCards}>
              <img src={file} alt="file" />
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              <Dropdown
                onSelect={(e) => handleAccountChange(e)}
                onClick={() => clickOnDropDown("Account")}
                autoClose = "inside"
              >
                <div className="account-label">Account</div>
                <Dropdown.Toggle variant="dark" className="dropdown-account">
                  <span className="acc-reg-name">
                    {selectedDropDownValues["aws:account"]?.name}
                  </span>
                  <img src={downArrow} alt="" className={`${!openedDropDowns["Account"] ? "p-2" : "uparrow p-2"}`} />
                </Dropdown.Toggle>
                <Dropdown.Menu variant="dark" className='menu-list'>
                  {awsAccounts.map((el) => (
                    <Dropdown.Item
                      key={el.id}
                      eventKey={JSON.stringify(el)}
                      style={{
                        background: `${
                          selectedDropDownValues[el.type]?.id === el.id
                            ? "#5A46FF"
                            : ""
                        }`,
                      }}
                    >
                      {el.name}
                      <span>
                        {selectedDropDownValues[el.type]?.id === el.id && (
                          <img src={checkSvg} alt="" />
                        )}
                      </span>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown
                onSelect={(e) => handleRegionChange(e)}
                onClick={() => clickOnDropDown("Regions")}
                autoClose = "inside"
              >
                <div className="account-label">Region</div>
                <Dropdown.Toggle variant="dark" className="dropdown-account">
                  <span className="acc-reg-name">
                    {selectedDropDownValues["aws:region"]
                      ? selectedDropDownValues["aws:region"].name
                      : "Regions"}
                  </span>
                  <img src={downArrow} alt="" className={`${!openedDropDowns["Regions"] ? "p-2" : "uparrow p-2"}`} />
                </Dropdown.Toggle>
                <Dropdown.Menu variant="dark" className='menu-list'>
                  {regions.map((region) => (
                    <Dropdown.Item
                      key={region.id}
                      eventKey={JSON.stringify(region)}
                      style={{
                        background: `${
                          selectedDropDownValues[region.type]?.id === region.id
                            ? "#5A46FF"
                            : ""
                        }`,
                      }}
                    >
                      {region.name}
                      <span>
                        {selectedDropDownValues[region.type]?.id ===
                          region.id && <img src={checkSvg} alt="" />}
                      </span>
                      <div className="resources-length">
                        Resources: {region.children.child.length}
                      </div>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <span className="search-box">
              <img className="search-img" src={searchImg} alt="" />
              <input
                type="search"
                placeholder="Search"
                className="search-bar"
                onChange={(e) => (setSearchBar(e.target.value))}
              />
              {searchBar &&
              <>
                <img className="clear-img" src={crossImg} alt="clear" onClick={() => setSearchBar("")}/>
                <div className="search-results">
                  {filteredResults.map((item) => (
                    <div key={item.id} className="search-result-item">
                      <span>{resourceIcon(item.type?.includes(":") && item.type.split(":")[1])}</span>
                      <span>{highlightText(item.name, searchBar)}</span>
                    </div>
                  ))}
                </div>
              </>}
            </span>
          </div>
        </div>
        <div className="infra-resources">
          <div className="resources-items">
            <div className="region-global-detail">
              <UniversalResource globalData={globalData} hideGlobal={hideGlobal}
                searchBar={searchBar} handleResourceIconClick={handleResourceIconClick}
                CustomToggle={CustomToggle}  highlightText={highlightText} allOpen={allOpen}
              />
              <Regions selectedDropDownValues={selectedDropDownValues}
                searchBar={searchBar} handleResourceIconClick={handleResourceIconClick}
                CustomToggle={CustomToggle} highlightText={highlightText} allOpen={allOpen}
              />
            </div>
            <div className="azs-vpcs-detail">
              <div className="azs-details">
                {availZones.map((az, index) => (
                  <div style={{ position: "relative" }} key={index}>
                  {!hideAz[index] ?
                  <>
                  <img src={drag} alt="drag" className="drag-img" onClick={() => handleClickAz(index)} />
                  <div className="universal-res" ref={el => azRef.current[index] = el}>
                    <AvailabilityZone
                      azname={az.name} index={index}
                      availZones={availZones} azChild={az.children}
                      searchBar={searchBar} handleResourceIconClick={handleResourceIconClick}
                      CustomToggle={CustomToggle} maxCategories={maxCategories}
                      highlightText={highlightText} allOpen={allOpen}
                    />
                    <div className="vpcs-data">
                      { vpcs.map((vpc) => {
                        const subnet = getSubnetData(vpc.id, az.id, infraViewResponse);
                        return subnet.length > 0 ? (
                          <div key={vpc.id} className="vpcs-parent">
                          <Vpcs
                            subnet={subnet}
                            vpc={vpc}searchBar={searchBar}
                            handleResourceIconClick={handleResourceIconClick}
                            CustomToggle={CustomToggle} openCards={openCards} allOpen={allOpen}
                            highlightText={highlightText}
                          />
                          </div>
                        ) : (
                          <div className="vpcs-parent" key={vpc.id}>
                            <div className="zero-subnets">
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    <div className="resize-handle" onMouseDown={(e) => handleMouseDown(index, e)}></div>
                  </div>
                  </>
                  :
                  <div className="hide-data">
                  <img src={arrow_right} alt="drag" className="right-arrow-img" onClick={() => handleClickAz(index)} />
                  <div style={{paddingTop:'10px'}}><img src={azIcon} alt="vector" /></div>
                  <span className="hide-data-name">{az.name}</span>
                </div>}
                </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showPopUp && <ResourceIconPopUp
       target={target}
       showPopUp={showPopUp}
       setShowPopUp={setShowPopUp}
       selectedResource={selectedResource}
       setSelectedResource={setSelectedResource}
       additionalInfo={additionalInfo}
       setAdditionalInfo={setAdditionalInfo}
       selectedDropDownValues={selectedDropDownValues}
      />}
    </div>
  );
};

export default InfraStructure;

